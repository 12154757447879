import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Divider } from 'react-materialize';
import './Header.css';
import LogoSvg from './logo.svg';
import { useAuth } from './App';
import axios from 'axios';

const Header = () => {
  const { isAuthenticated } = useAuth();
  // const navigate = useNavigate();

  const handleLogout = () => {
    axios.post('/api/v1/users/logout')
    .then(response => {
      if (response.status === 200) {
        // Perform client-side cleanup
        localStorage.removeItem("user");
        // Reload the app
        window.location.href = "/login";
      }
    })
    .catch(error => {
      // Handle any errors here, like showing a message to the user
      console.error("Logout failed:", error);
    });
  };

  const menuItems = !isAuthenticated ? [
    <NavLink to="/register" key="register" className="sidenav-close">
        <i className="material-icons left">person</i>Register
    </NavLink>,
    <NavLink to="/signin" key="signin" className="sidenav-close">
      <i className="material-icons left">login</i>Sign in
    </NavLink>
  ] : [
    <NavLink to="/generate" key="generate" className="sidenav-close">
      Generate
    </NavLink>,
    // <NavLink to="#" key="o2" className="sidenav-close">
    //   User Option 2
    // </NavLink>,
    <Divider key="divider" />,
    <NavLink onClick={handleLogout} key="logout" className="sidenav-close">
      Logout
    </NavLink>
  ];

  return (
    <Navbar
      alignLinks="right"
      brand={<a className="brand-logo" href="/"><img className="logo-img" src={LogoSvg} alt="Logo" /></a>}
      id="mobile-nav"
      // menuIcon={<a href="#" data-target="mobile-nav" className="sidenav-trigger right"><i className="material-icons">menu</i></a>}
      options={{
        draggable: true,
        edge: 'right',
        inDuration: 250,
        outDuration: 200,
        preventScrolling: true,
        closeOnClick: true
      }}
    >
      {/* <Dropdown
        id="Dropdown_6"
        className="desktop-dropdown"
        options={{
          alignment: 'left',
          autoTrigger: true,
          closeOnClick: true,
          constrainWidth: true,
          container: null,
          coverTrigger: true,
          hover: false,
          inDuration: 150,
          outDuration: 250
        }}
        trigger={<a href="#!">Dropdown<i className="material-icons right">arrow_drop_down</i></a>}
      >
        {}
      </Dropdown> */}
      {menuItems}
    </Navbar>
  );
};

export default Header;
